// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Configurația Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCg_loc3CEPSbnydMqZvN-Cwt19z-Q6y18",
  authDomain: "ajutorescu.firebaseapp.com",
  projectId: "ajutorescu",
  storageBucket: "ajutorescu.appspot.com",
  messagingSenderId: "571159076946",
  appId: "1:571159076946:web:7e4f71a054cfd3e3c17f8b",
  measurementId: "G-GLF73RNKDF",
};

// Inițializarea aplicației Firebase
const app = initializeApp(firebaseConfig);

// Inițializarea Firestore
const db = getFirestore(app);

// Exportăm instanța Firestore pentru a fi folosită în alte fișiere
export { db };
