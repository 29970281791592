import React from "react";
import "./navbar.css";
import Ajutorescu from "./assets/name.png";
import Priceput from "./assets/priceput.png";

const Navbar = () => {
    return (
      <div className="navbar-container">
        <div className="logo-container">
          <img className="logo-image" src={Ajutorescu} alt="ajutorescu" />
          <img className="logo-image" src={Priceput} alt="ajutorescu" />
        </div>
      </div>
    );
};

export default Navbar;
